import { NewsListItemFragment } from '@amf/shared/types/graphql';
import classNames from 'classnames';

import NewsTile from 'components/news/NewsTile';

type Props = {
  news: NewsListItemFragment[];
  prefixes: { newsList: string };
  className?: string;
};

function NewsGrid({ news, prefixes, className }: Props) {
  const testNews = [...news, ...news, ...news, ...news, ...news];
  const lgNews = [...testNews].slice(0, 3);
  const mdNews = [...testNews].slice(2, 5);
  const smNews = [...testNews].slice(5, 9);
  return (
    <div className={classNames('grid grid-cols-12 gap-2 lg:gap-3', className)}>
      {lgNews.map((item, key) => (
        <NewsTile
          key={key}
          news={item}
          prefix={prefixes.newsList}
          size='md'
          className='block col-span-12 lg:col-span-4'
        />
      ))}
      {/*mdNews.map((item, key) => (
        <NewsTile
          key={key}
          news={item}
          prefix={prefixes.newsList}
          size='md'
          className='hidden sm:block col-span-12 lg:col-span-4'
        />
      ))}
      {smNews.map((item, key) => (
        <NewsTile
          key={key}
          news={item}
          prefix={prefixes.newsList}
          size='sm'
          className='col-span-6 xl:col-span-3 hidden lg:block'
        />
      ))*/}
    </div>
  );
}

export default NewsGrid;
