import { ReactNode } from 'react';
import classNames from 'classnames';
import Container from '@amf/shared/components/layout/Container';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';

type Props = {
  onClose: () => void;
  children: ReactNode;
  className?: string;
};

function VideoPreview({ onClose, className, children }: Props) {
  return (
    <div
      className={classNames(
        'fixed top-0 left-0 w-full h-full z-40',
        'flex items-center justify-center',
        className,
      )}
    >
      <button
        type='button'
        className='absolute top-0 left-0 w-full h-full bg-neutral-950/80'
        onClick={() => onClose()}
      />
      <Container className='w-full relative z-10 flex flex-col gap-3'>
        <div className='w-full text-right'>
          <button
            type='button'
            className='inline-flex w-10 h-10 text-white items-center justify-center'
            onClick={() => onClose()}
          >
            <XCircleIcon className='w-10 h-10' />
          </button>
        </div>
        <motion.video
          autoPlay
          controls
          className='aspect-video w-full shadow-2xl rounded-lg bg-black'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          {children}
        </motion.video>
      </Container>
    </div>
  );
}

export default VideoPreview;
