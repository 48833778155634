import { useState } from 'react';
import classNames from 'classnames';
import { PlayCircleIcon } from '@heroicons/react/24/solid';
import VideoPreview from '@amf/shared/components/content/VideoPreview';
import { useTranslate } from '@tolgee/react';
import { AnimatePresence } from 'framer-motion';

import Button from 'components/form/Button';

type Props = {
  className?: string;
};

function HomeBanner({ className }: Props) {
  const { t } = useTranslate('wmf');
  const [preview, setPreview] = useState(false);
  return (
    <>
      <div
        className={classNames(
          'w-full bg-wmf-navy rounded-xl text-white shadow-xl',
          'grid grid-cols-12 xl:gap-3 items-center',
          'divide-y xl:divide-x xl:divide-y-0 divide-white/20',
          className,
        )}
      >
        <div className='col-span-12 xl:col-span-4 px-3 sm:px-8 xl:px-10 py-3 sm:py-6 xl:py-8 font-bold font-primary text-xl lg:text-2xl xl:text-3xl flex flex-col sm:flex-row xl:flex-col justify-center xl:justify-normal gap-0 xl:gap-2 divide-none sm:divide-x xl:divide-none divide-wmf-natural'>
          <p className='sm:px-4 xl:px-0 text-center sm:text-left'>{t('home.banner.location')}</p>
          {/*
          <time className='sm:px-4 xl:px-0 text-center sm:text-left'>{t('home.banner.time')}</time>
          <p className='sm:px-4 xl:px-0 text-center sm:text-left'>{t('home.banner.teams')}</p>
          */}
        </div>
        <div className='col-span-12 xl:col-span-8 px-3 sm:px-8 xl:px-10 py-3 sm:py-6 xl:py-8 flex flex-col gap-3 sm:gap-6 items-center xl:items-start text-center xl:text-left'>
          <p className='font-primary text-base sm:text-lg lg:text-xl leading-relaxed'>
            {t('home.banner.description')}
          </p>
          <div className='flex flex-col sm:flex-row items-center gap-3 w-full sm:w-auto'>
            <Button
              variant='white'
              icon={PlayCircleIcon}
              className='w-full sm:w-fit'
              onClick={() => setPreview(true)}
            >
              {t('home.banner.play')}
            </Button>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {preview && (
          <VideoPreview onClose={() => setPreview(false)}>
            <source src='https://amf-data.fra1.digitaloceanspaces.com/wmf/2024.mp4' />
          </VideoPreview>
        )}
      </AnimatePresence>
    </>
  );
}

export default HomeBanner;
