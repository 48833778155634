import fromUnixTime from 'date-fns/fromUnixTime';
import { useEffect, useState } from 'react';

import useCountdown from 'hooks/useCountdown';

function Countdown() {
  const [days, hours, minutes, seconds, all] = useCountdown(fromUnixTime(1698310800));
  const [countdown, setCountdown] = useState(false);
  useEffect(() => setCountdown(true), []);

  return null;
  /*if (!countdown || Number(all) < 0) return null;
  return (
    <div className='w-fit font-primary text-3xl bg-white rounded-full text-wmf-navy font-black px-6 py-3 leading-none mb-2 sm:mb-6 mx-auto lg:mx-0'>
      {days}d {hours}h {minutes}m {seconds}s
    </div>
  );*/
}

export default Countdown;
