import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import React, { useState } from 'react';
import { RankingFragment } from '@amf/shared/types/graphql';
import { getCountryISO2 } from '@amf/shared/utils/country';
import CountryFlag from 'react-country-flag';

import Button from 'components/form/Button';

type Props = {
  rankings: RankingFragment[];
  className?: string;
};

function HomeRanking({ rankings, className }: Props) {
  const { t } = useTranslate('wmf');
  const [more, setMore] = useState(false);
  return (
    <div className={classNames('grid grid-cols-1 sm:grid-cols-2 gap-2 relative', className)}>
      {(more ? rankings : rankings.slice(0, 10)).map((ranking, key) => (
        <div
          key={ranking.id}
          className='grid grid-cols-12 md:flex items-center h-14 border border-wmf-natural px-3 md:px-5 gap-3 md:gap-5 font-primary font-bold text-sm md:text-base'
        >
          <div className='col-span-1 md:min-w-[24px] md:text-right'>{key + 1}</div>
          <div className='col-span-9 flex-grow flex items-center gap-1.5 md:gap-3'>
            <CountryFlag
              svg
              countryCode={getCountryISO2(ranking.countryCode)}
              className='h-4 border border-neutral-200'
              style={{ width: undefined, height: undefined }}
            />
            <span className='truncate'>{ranking.name}</span>
          </div>
          <div className='col-span-2 text-right md:text-left'>{ranking.points}</div>
        </div>
      ))}
      {!more && (
        <>
          <div className='absolute bottom-0 left-0 w-full h-full bg-gradient-to-b from-white/0 to-white pointer-events-none select-none' />
          <div className='sm:col-span-2 text-center relative z-10'>
            <Button variant='green' onClick={() => setMore(true)}>
              {t('home.ranking.more')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default HomeRanking;
