import * as process from 'process';

import { useTranslate } from '@tolgee/react';
import { GetStaticProps } from 'next';
import { sdk } from '@amf/shared/lib/graphQlClient';
import {
  AdFragment,
  BannerFragment,
  DrawDetailItemFragment,
  NewsListItemFragment,
  RankingFragment,
  SeasonFragment,
} from '@amf/shared/types/graphql';
import { getLanguageLocale } from '@amf/shared/utils/languages';
import { getPartners, NavigationProps } from '@amf/shared/utils/navigation';
import Ad from '@amf/shared/components/ad/Ad';
import React from 'react';
import LeagueTableDashboardGrid from '@amf/shared/components/dashboard/LeagueTableDashboardGrid';
import MatchDashboardList from '@amf/shared/components/dashboard/MatchDashboardList';

import Meta from 'components/Meta';
import Layout from 'components/layout/Layout';
import Slider from 'components/slider/Slider';
import HomeBanner from 'components/home/HomeBanner';
import Container from 'components/layout/Container';
import NewsGrid from 'components/news/NewsGrid';
import HomeRanking from 'components/home/HomeRanking';
import HomeLocation from 'components/home/HomeLocation';
import { AppLinks } from 'utils/links';

type Props = NavigationProps & {
  ranking: RankingFragment[];
  news: NewsListItemFragment[];
  banners: BannerFragment[];
  ads: AdFragment[];
  draw: DrawDetailItemFragment | null;
  seasons: SeasonFragment[];
};

export default function Page({
  navigation,
  news,
  banners,
  ranking,
  ads,
  draw,
  seasons,
  partners,
}: Props) {
  const { t } = useTranslate(['wmf', 'general']);
  return (
    <Layout navigation={navigation} partners={partners}>
      <Meta />
      <Slider banners={banners} />
      <Container className='relative'>
        <HomeBanner className='relative -top-12 -mb-12 sm:-top-12 sm:-mb-24 md:-top-32 md:-mb-32' />
        <div className='my-12 sm:my-24 flex flex-col gap-4 md:gap-6 lg:gap-10'>
          <h2 className='text-3xl md:text-5xl font-black font-primary text-neutral-950 m-0'>
            {t('news.list.title')}
          </h2>
          <NewsGrid news={news} prefixes={{ newsList: AppLinks.newsList }} />
        </div>
        {ads.map(ad => (
          <Ad key={ad.id} ad={ad} />
        ))}
        {seasons.length > 0 && (
          <div className='mb-24 lg:mb-80'>
            <LeagueTableDashboardGrid
              season={seasons[0]}
              prefixes={{ team: AppLinks.team, table: AppLinks.table, match: AppLinks.match }}
              colors={[
                '#f87171',
                '#fbbf24',
                '#a3e635',
                '#34d399',
                '#38bdf8',
                '#818cf8',
                '#c084fc',
                '#ec4899',
              ]}
            />
            <MatchDashboardList
              season={seasons[0]}
              prefixes={{ match: AppLinks.match, team: AppLinks.team, matches: AppLinks.matches }}
            />
          </div>
        )}
      </Container>
      <HomeLocation className='mt-12 sm:mt-24' />
      <div className='relative mt-24 lg:mt-80'>
        <Container>
          <div className='my-12 sm:my-24 flex flex-col gap-4 md:gap-6 lg:gap-10'>
            <h2 className='text-3xl md:text-5xl font-black font-primary text-neutral-950 m-0'>
              {t('home.ranking.title')}
            </h2>
            <HomeRanking rankings={ranking} />
          </div>
        </Container>
      </div>
    </Layout>
  );
}
export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => {
  const { navigation } = await sdk.Navigation({
    union: process.env.NEXT_PUBLIC_UNION,
    locale: getLanguageLocale(locale!),
  });

  const { newsList: news } = await sdk.NewsList({
    union: process.env.NEXT_PUBLIC_UNION,
    offset: 0,
    limit: 4,
  });

  const { bannerList: banners } = await sdk.BannerList({ union: process.env.NEXT_PUBLIC_UNION });
  const { seasons } = await sdk.SeasonList({ union: process.env.NEXT_PUBLIC_UNION });
  const { rankingList } = await sdk.RankingList({ union: process.env.NEXT_PUBLIC_UNION });
  const { adList } = await sdk.AdList({
    union: process.env.NEXT_PUBLIC_UNION,
    type: 'homepageWide',
  });

  const { draw } = await sdk.DrawDetail({
    union: process.env.NEXT_PUBLIC_UNION,
    id: '61bd614c-ba2e-48d7-ac30-1a486d084373',
  });

  return {
    props: {
      navigation,
      news,
      banners,
      seasons,
      ranking: rankingList,
      ads: adList,
      draw: draw ?? null,
      partners: await getPartners(),
    },
    revalidate: 30,
  };
};
