/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import { useTranslate } from '@tolgee/react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import classNames from 'classnames';

import Container from 'components/layout/Container';
import Button from 'components/form/Button';
import IconKhaimah from 'assets/brand/IconKhaimah';

type Props = {
  className?: string;
};

function HomeLocation({ className }: Props) {
  const { t } = useTranslate('wmf');
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({ target: ref, offset: ['start end', 'start start'] });
  const scaleY = useTransform(scrollYProgress, [0, 0.7], [1, 1.5]);
  const maxWidth = useTransform(scrollYProgress, [0, 0.7], ['80vw', '100vw']);
  const borderRadius = useTransform(scrollYProgress, [0.5, 1], [24, 0]);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [0, 1]);

  return (
    <div className={classNames('relative', className)} ref={ref}>
      <div className='absolute top-0 left-0 w-full h-full'>
        <motion.div
          style={{ scaleY, maxWidth, borderRadius }}
          className='hidden lg:block w-full h-full bg-gradient-to-r from-sky-500 to-emerald-500 mx-auto shadow-xl pointer-events-none'
        />
        <div className='block lg:hidden w-full h-full bg-gradient-to-r from-sky-500 to-emerald-500 pointer-events-none' />
      </div>
      <Container className='pt-12 pb-16 md:pb-24 lg:py-24 flex flex-col relative'>
        <motion.div
          style={{ opacity }}
          className='grid grid-cols-1 lg:grid-cols-2 items-center gap-6 md:gap-12'
        >
          <div className='flex flex-col gap-3 md:gap-6 order-2 lg:order-1'>
            <h2 className='text-3xl md:text-5xl font-black font-primary text-white m-0'>
              {t('home.location.title')}
            </h2>
            <p className='sm:text-lg text-white font-primary'>{t('home.location.description')}</p>
          </div>
        </motion.div>
        <div className='grid grid-cols-2 sm:grid-cols-3 gap-3 mt-4 md:mt-8 lg:mt-24 pointer-events-none select-none min-h-[120px] sm:min-h-0'>
          <div className='h-[200px] sm:h-[240px] md:h-[280px] lg:h-[320px] xl:h-[400px]'>
            <img
              src='https://amf-data.fra1.digitaloceanspaces.com/wmf/8c29f3dc-a9a4-47ae-850b-988553ee8621/content/images/mniw0w22uc-WhatsApp-Image-13-2024-08-12-at-16.36.06.jpeg'
              alt=''
              className='h-full w-full object-cover'
            />
          </div>
          <div className='h-[200px] sm:h-[240px] md:h-[280px] lg:h-[320px] xl:h-[400px]'>
            <img
              src='https://amf-data.fra1.digitaloceanspaces.com/wmf/8c29f3dc-a9a4-47ae-850b-988553ee8621/content/images/k37cgflfyv-WhatsApp-Image-16-2024-08-12-at-16.36.30.jpeg'
              alt=''
              className='h-full w-full object-cover'
            />
          </div>
          <div className='h-[200px] sm:h-[240px] md:h-[280px] lg:h-[320px] xl:h-[400px] hidden sm:block'>
            <img
              src='https://amf-data.fra1.digitaloceanspaces.com/wmf/8c29f3dc-a9a4-47ae-850b-988553ee8621/content/images/0lkbr60teh-WhatsApp-Image-2-2024-08-12-at-16.33.45.jpeg'
              alt=''
              className='h-full w-full object-cover'
            />
          </div>
        </div>
        <Link href='/mali-losinj-croatia' passHref>
          <a className='absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2 shadow-xl bg-white rounded-lg w-[80%] sm:w-auto'>
            <Button
              variant='white'
              className='sm:whitespace-nowrap min-h-[3.5rem] py-2 h-auto w-full justify-center sm:h-14'
            >
              {t('home.location.button')}
            </Button>
          </a>
        </Link>
      </Container>
    </div>
  );
}

export default HomeLocation;
