/* eslint-disable @next/next/no-img-element */
import { useKeenSlider } from 'keen-slider/react';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { BannerFragment } from '@amf/shared/types/graphql';
import Button, { ButtonProps } from '@amf/shared/components/button/Button';
import { motion, useScroll, useTransform } from 'framer-motion';

import Countdown from 'components/utils/Countdown';
import Container from 'components/layout/Container';

const SliderTransitionDelay = 10000;
const SliderTransitionSpeed = 640;

interface SliderProps {
  banners: BannerFragment[];
  buttonProps?: ButtonProps;
}

export default function Slider({ banners, buttonProps }: SliderProps) {
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 2000], ['0%', '30%']);
  const timer = useRef<number>();
  const [pause, setPause] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    duration: SliderTransitionSpeed,
    loop: true,
    dragStart: () => setPause(true),
    dragEnd: () => setPause(false),
    slideChanged() {
      setSlideIndex(-1);
    },
    afterChange(s) {
      const index = s.details().relativeSlide;
      setSlideIndex(index);
    },
  });

  useEffect(() => {
    if (banners.length > 1) {
      window.clearInterval(timer.current);
      timer.current = window.setInterval(() => {
        if (!pause && slider) {
          slider.next();
        }
      }, SliderTransitionDelay);
    }

    return () => {
      window.clearInterval(timer.current);
    };
  }, [slideIndex, pause, slider, banners]);

  return (
    <motion.div
      ref={sliderRef}
      style={{ y }}
      className='w-full relative h-[400px] sm:h-[420px] md:h-[550px] xl:h-[680px] flex'
    >
      {banners.map(banner => (
        <div
          key={banner.id}
          className={classNames('h-full relative', 'keen-slider__slide', {
            'text-center': banner.position === 'center',
            'text-right': banner.position === 'right',
          })}
        >
          <Container className='h-full relative z-10 flex flex-col justify-center pt-40 pb-32'>
            <div className='xl:w-1/2'>
              <Countdown />
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className='before:hidden m-0 font-black text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-primary text-white drop-shadow text-center lg:text-left'
              >
                {banner.title}
              </motion.h1>
              {banner.buttonTitle && banner.buttonLink && (
                <a href={banner.buttonLink}>
                  <Button variant='blue' className='' {...buttonProps}>
                    {banner.buttonTitle}
                  </Button>
                </a>
              )}
            </div>
          </Container>
          {banner.imageUrl ? (
            <img
              src={banner.imageUrl}
              alt={banner.title}
              className='absolute top-0 left-0 w-full h-full object-cover object-center pointer-events-none select-none'
            />
          ) : (
            <video
              autoPlay
              controls={false}
              muted
              loop
              className='absolute top-0 left-0 w-full h-full bg-neutral-700 pointer-events-none select-none object-cover object-center'
            >
              <source src='https://amf-data.fra1.digitaloceanspaces.com/wmf/2024.mp4' />
            </video>
          )}
          <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-l from-wmf-navy/40 to-wmf-navy/80 sm:from-wmf-navy/20 sm:to-wmf-navy/80 pointer-events-none select-none' />
        </div>
      ))}
    </motion.div>
  );
}
