/* eslint-disable @next/next/no-img-element */
import { NewsListItemFragment } from '@amf/shared/types/graphql';
import fromUnixTime from 'date-fns/fromUnixTime';
import Link from 'next/link';
import classNames from 'classnames';
import { truncate } from '@amf/shared/utils/string';
import { formatDate } from '@amf/shared/utils/date';

type Props = {
  news: NewsListItemFragment;
  prefix: string;
  size: 'md' | 'sm';
  className?: string;
};

function NewsTile({ news, size, prefix, className }: Props) {
  const publishedAt = fromUnixTime(news.publishedAt);
  const content = (
    <div
      className={classNames(
        'w-full relative flex flex-col sm:flex-row items-end group overflow-hidden',
        'bg-gradient-to-br from-neutral-700 to-neutral-500',
        'rounded md:rounded-none',
        {
          'h-auto md:h-[240px] lg:h-[320px] xl:h-[400px]': size === 'md',
          'lg:h-[200px] xl:h-[200px]': size === 'sm',
        },
      )}
    >
      {news.photoUrl && (
        <>
          <img
            className={classNames(
              'sm:absolute top-0 left-0 w-full sm:h-full pointer-events-none select-none object-cover object-center',
              'sm:group-hover:scale-[1.1] sm:group-hover:blur-sm transition-all duration-1000',
            )}
            src={news.photoUrl}
            alt={news.title}
          />
          <div
            className={classNames('absolute top-0 left-0 w-full h-full bg-gradient-to-b', {
              'from-black/20 sm:from-black/60 md:from-black/0 to-black/40 sm:to-black/80 md:to-black/60':
                size === 'md',
              'from-black/20 to-black/80': size === 'sm',
            })}
          />
        </>
      )}
      <div
        className={classNames('p-3 md:p-6 font-primary text-white flex flex-col relative z-10', {
          'gap-1.5 sm:gap-3': size === 'md',
          'gap-1.5': size === 'sm',
        })}
      >
        <h3
          className={classNames(
            'm-0 normal-case font-primary font-bold text-white drop-shadow leading-tight',
            {
              'text-lg sm:text-xl': size === 'md',
              'text-lg': size === 'sm',
            },
          )}
        >
          {news.title}
        </h3>
        <time
          className={classNames('font-bold', {
            'text-xs sm:text-sm': size === 'md',
            'text-xs': size === 'sm',
          })}
        >
          {formatDate(publishedAt, 'dd/MM/yyyy, HH:mm')}
        </time>
        {size !== 'sm' && (
          <p className='group-hover:opacity-100 group-hover:max-h-[200px] md:opacity-0 md:max-h-0 transition-all duration-700 text-sm sm:text-base'>
            {truncate(news.perex, 256)}
          </p>
        )}
      </div>
    </div>
  );

  if (news.externalLink) {
    return (
      <a href={news.externalLink} target='_blank' rel='noreferrer' className={className}>
        {content}
      </a>
    );
  }

  return (
    <Link href={`${prefix}/${news.paths.active}`} passHref>
      <a className={className}>{content}</a>
    </Link>
  );
}

export default NewsTile;
